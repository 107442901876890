import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Nav } from '../../types';
import './Sidebar.css';

function Sidebar(props: Nav) {
  const { sidebarOpen, handleSidebarOpen } = props;
  console.log('open', sidebarOpen);

  return (
    <div
      className='mobile-menu menu-overlay'
      style={{ display: sidebarOpen ? 'block' : 'none' }}
    >
      <div className='main-mobile-menu-inner'>
        <ul
          id='primary-navigation-overlay'
          className='menu mobile-primary-menu'
        >
          <li className='menu-item'>
            <a href='/'>Home</a>
          </li>
          <li className='menu-item'>
            <a href='https://mylightspeed.app/VEWKEZNQ/C-ordering/menu'>Our Menu</a>
          </li>
          <li className='menu-item'>
            <a href='https://www.doordash.com/store/layalina-mediterranean-restaurant-denton-892410/'>Order Now</a>
          </li>
          <li className='menu-item'>
            <a href='/reservations'>Reservations</a>
          </li>
          <li className='menu-item'>
            <a href='/catering'>Catering</a>
          </li>
          <li className='menu-item'>
            <a href='/gallery'>Gallery</a>
          </li>
          <li className='menu-item'>
            <a href='/contact'>Contact</a>
          </li>
        </ul>
      </div>
      <button
        type='button'
        id='btn-menu-close'
        className='btn-menu-close'
        aria-label='Close menu'
        onClick={() => handleSidebarOpen(false)}
      >
        <FontAwesomeIcon
          style={{ fontSize: '32px', fontWeight: 500 }}
          icon={faClose}
        />
      </button>
    </div>
  );
}

export default Sidebar;
